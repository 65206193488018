import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import withAppContext from '../../../withAppContext';

import {
  colors,
  mobileThresholdPixels,
} from '../v2/styledComponents';
import pricing, { pricingInverse } from '../../../services/pricing';

const ClothContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
  }
`;

const AlterationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const AlterationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: ${mobileThresholdPixels}) {
    max-width: 82%;
    margin-bottom: 0px;
    margin-left: 0px;
  }
`;

const TextSummary = styled.p`
  font-family: Roboto;
  font-size: ${props => props.light ? '15px' : '18px'};
  text-align: left;
  ${props => props.bold && 'font-weight: bold;'}
  ${props => props.light && 'font-weight: 300;'}
  margin-right: 4px;
  margin: 10px 4px 0px 0px;
  color: ${colors.navy};
`;

const getClothName = (cloth) => {
  try {
    return pricingInverse[cloth.items[0].id].cloth;
  } catch (e) {
    return null;
  }
};

const OrderSummaryCloth = ({ context: { isMobile }, cloth }) => {
  const clothName = getClothName(cloth, pricing);
  if (!clothName) return null;
  const clothItems = (cloth && cloth.items && cloth.items.filter(item => item.quantity > 0)) || [];
  return (
    <div>
      <ClothContainer>
        <TextSummary light>{clothName}</TextSummary>
        <AlterationsContainer>
          {clothItems.map((item) => {
            const refItem = pricingInverse[item.id];
            if (!refItem) return null;
            const { name, category } = refItem;
            const basePrice = item.price * item.quantity;
            const liningPrice = cloth.liningsNumber * (item.liningUnitPrice || 0) * item.quantity;
            const price = basePrice + liningPrice;
            const key = `${cloth.name}_${name}`;
            const nameToDisplay = name.toLowerCase();
            return (
              <AlterationContainer key={key}>
                {!isMobile && <StyledContainer>
                  <TextSummary light>- {category}</TextSummary>
                  <TextSummary light>
                    {(item.quantity > 1) ? `${item.quantity}X - ${nameToDisplay}` : nameToDisplay}
                  </TextSummary>
                </StyledContainer>}
                {isMobile && <StyledContainer>
                  <TextSummary light>
                    - {category} {(item.quantity > 1) ? `${item.quantity}X - ${nameToDisplay}` : nameToDisplay}
                  </TextSummary>
                </StyledContainer>}
                <TextSummary bold>{price} €</TextSummary>
              </AlterationContainer>
            );
          })}
          {(cloth.liningsNumber > 0) &&
            <AlterationContainer>
              <TextSummary light>
                (doublure(s) : {cloth.liningsNumber})
              </TextSummary>
            </AlterationContainer>
          }
        </AlterationsContainer>
      </ClothContainer>
    </div>
  );
};

OrderSummaryCloth.propTypes = {
  cloth: PropTypes.shape({}).isRequired,
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

export default withAppContext(OrderSummaryCloth);
