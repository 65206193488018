import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment-timezone';
import withAppContext from '../../../withAppContext';

import {
  colors,
  mobileThresholdPixels,
  margins,
} from '../v2/styledComponents';
import { toFormattedBoldText } from '../../../services/formatting';
import { getGroupedSlots, getGroupedSlotsPerDay } from '../../../services/slotsFormatting';
import {
  getTotalPaid,
  getMinOrderAmountFee,
  getTotalDiscount,
} from '../../../core/services/orderPrice';
import OrderSummaryCloth from './OrderSummaryCloth';
import momentFr from '../../../tilli-components/src/config/moment.fr';

moment.updateLocale('fr', momentFr);
moment.tz.setDefault('Europe/Paris');


const StyledContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.column ? 'column' : 'row'};
  justify-content: ${props => props.justifyContent || 'space-between'};
  ${props => props.alignItems && `align-items: ${props.alignItems};`}
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: ${props => props.marginBottomMobile || margins.l};
    margin-left: 0px;
    ${props => !props.row && 'flex-direction: column;'}
    ${props => !props.noMarginTop && 'margin-top: 0;'}
  }
`;

const TextSummary = styled.p`
  font-family: Roboto;
  font-size: ${props => props.light ? '15px' : '18px'};
  text-align: left;
  ${props => props.bold && 'font-weight: bold;'}
  ${props => props.light && 'font-weight: 300;'}
  margin-bottom: ${props => props.marginBottom ? '47px' : '5px'};
  margin-top: ${props => props.marginTop ? '30px' : '6px'};
  ${props => props.marginRight && 'margin-right: 4px;'}
  color: ${colors.navy};
  ${props => props.grey && 'opacity: 0.3;'}
  @media (max-width: ${mobileThresholdPixels}) {
    ${props => props.smallFontSize && 'font-size: 14px;'}
    ${props => props.mobile && 'margin-top: 0px;'}
    ${props => props.marginBottomMobile && `margin-bottom: ${props.marginBottomMobile}`};
  }
`;

const Line = styled.hr`
  width: 100%;
  color: ${colors.navy};
  opacity: 0.3;
  margin: 16px 0;
`;

const getWrittenDate = (slot, index, arr) => {
  let rdvDate = moment(slot.begin).format('D MMMM');
  let previousRdvDate = '';
  if (index > 0) previousRdvDate = moment(arr[index - 1].begin).format('D MMMM');
  rdvDate = previousRdvDate === rdvDate ? 'ou' : `Le ${rdvDate}`;
  const rdvBegin = moment(slot.begin).format('H[h]mm');
  const rdvEnd = moment(slot.end).format('H[h]mm');
  return { rdvDate, rdvBegin, rdvEnd };
};

const renderSlots = (isMobile, slots) => {
  if (!isMobile) {
    const groupedSlots = getGroupedSlots(slots);
    return (
      <StyledContainer justifyContent="left" alignItems="baseline" marginBottom="0px">
        <TextSummary marginTop marginRight>
          {toFormattedBoldText(
            '<bold>Rendez-vous :<bold>',
          )}
        </TextSummary>
        <StyledContainer column alignItems="flex-end">
          {groupedSlots.map((slot, index, arr) => {
            const { rdvDate, rdvBegin, rdvEnd } = getWrittenDate(slot, index, arr);
            return (
              <TextSummary key={slot.id}>
                {rdvDate} entre {rdvBegin} et {rdvEnd}
              </TextSummary>
            );
          })}
        </StyledContainer>
      </StyledContainer>
    );
  }
  const groupedSlotsPerDay = getGroupedSlotsPerDay(slots);
  return (
    <StyledContainer column alignItems="flex-start" noMarginTop marginBottomMobile="15px">
      {groupedSlotsPerDay.map((oneDaySlots, index) => {
        const dateLine = oneDaySlots.slots.reduce((accLine, slot) => {
          const slotIndex = accLine === '' ? 0 : 1;
          const { rdvDate, rdvBegin, rdvEnd } = getWrittenDate(slot, slotIndex, oneDaySlots.slots);
          return `${accLine} ${rdvDate} entre ${rdvBegin} et ${rdvEnd}`;
        }, '');
        if (index > 0) {
          return (
            <TextSummary smallFontSize key={oneDaySlots.id} mobile>{dateLine}</TextSummary>
          );
        }
        return (
          <TextSummary smallFontSize key={oneDaySlots.id} marginRight mobile>
            {toFormattedBoldText(
              `<bold>Rendez-vous :<bold> ${dateLine}`,
            )}
          </TextSummary>
        );
      })}
    </StyledContainer>
  );
};

const OrderSummary = ({ context: { isMobile }, orderToPay }) => {
  const { locality, street, zipcode } = orderToPay.fullAddress;
  const totalPaid = getTotalPaid(orderToPay);
  const minOrderAmountFee = getMinOrderAmountFee(orderToPay);
  const discountValue = getTotalDiscount(orderToPay);
  const discountWithValue0 = orderToPay?.discounts.find(discount => discount.value === 0);

  return (
    <div>
      {renderSlots(isMobile, orderToPay.rdv1Slots)}
      <StyledContainer alignItems="center" row marginBottomMobile="15px" >
        <TextSummary smallFontSize marginTop mobile>
          {toFormattedBoldText(
            `<bold>Adresse : <bold>${street} ${zipcode} ${locality}`,
          )}
        </TextSummary>
      </StyledContainer>
      <Line />
      <TextSummary bold>Retouches</TextSummary>
      {(minOrderAmountFee === 0) && orderToPay.clothes && orderToPay.clothes.map(cloth =>
        <OrderSummaryCloth
          cloth={cloth}
          key={cloth.name}
        />,
      )}
      {(minOrderAmountFee > 0) &&
        <StyledContainer row>
          <TextSummary light>Minimum de commande</TextSummary>
          <TextSummary bold>{orderToPay.minOrderAmount}€</TextSummary>
        </StyledContainer>
      }
      <TextSummary light marginBottom marginBottomMobile="40px" marginTop>
        Des vêtements à ajouter ?
        C’est possible !
        Il vous suffit de le demander à votre Tilliste pendant votre rendez-vous.
      </TextSummary>
      <StyledContainer row marginBottomMobile="15px">
        <TextSummary bold>Frais de déplacement</TextSummary>
        <TextSummary bold>{orderToPay.deliveryFee} €</TextSummary>
      </StyledContainer>
      {(discountValue > 0 || discountWithValue0)
        && orderToPay?.discounts.length > 0 &&
        <StyledContainer row marginBottomMobile="15px">
          <TextSummary bold >Réduction</TextSummary>
          <TextSummary bold grey>- {discountValue} €</TextSummary>
        </StyledContainer>
      }
      {(discountValue > 0 || discountWithValue0)
        && orderToPay?.discounts.length > 0 && orderToPay.discounts.map((discount) => {
        if (discount.value < 0) return null;
        let promoLine = '';

        if (discount?.referrer?.firstname) {
          promoLine = `Parrainage ${discount.referrer.firstname} ${discount.referrer.lastname}`;
        } else {
          if (!discount?.promoCode) return null;
          promoLine = discount?.promoCode?.code;
        }

        return (
          <StyledContainer
            key={discount._id} row marginBottomMobile="15px"
          >
            <TextSummary light grey>{promoLine}</TextSummary>
          </StyledContainer>
        );
      })}
      <Line />
      <StyledContainer row marginBottomMobile="0px">
        <TextSummary bold>Prix total</TextSummary>
        <TextSummary bold>{totalPaid} €</TextSummary>
      </StyledContainer>
    </div>
  );
};

OrderSummary.propTypes = {
  orderToPay: PropTypes.shape({}).isRequired,
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

export default withAppContext(OrderSummary);
