import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MenuBar from '../home/MenuBar';
import Footer from '../home/v3/Footer';
import CreditCard2 from './CreditCard2';
import {
  colors,
  ErrorText,
  Content,
  Container,
  Button,
  mobileThresholdPixels,
} from '../home/v2/styledComponents';
import {
  isCardTypeValid,
  isCardNumberValid,
  errorMessages,
} from '../../services/mangopay';
import callApi from '../../services/api';
import OrderSummary from '../home/v3/OrderSummary';

import mangoPayLogo from '../../assets/Mangopay2.png';
import axaLogo from '../../assets/axa.png';
import acceptedCBs from '../../assets/cb.png';

const TextTitle = styled.p`
  font-family: Roboto;
  font-size: 30px;
  line-height: 42px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 0;
  color: ${colors.navy};
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 22px;
    line-height: 31px;
  }
`;

const TextSubTitle = styled.p`
  font-family: Roboto;
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  margin-top: 0;
  color: ${colors.navy};
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 15px 35px 49px 35px;
    font-size: 14px;
    line-height: 20px;
  }
`;

const TextTitleContainer = styled.p`
  font-family: Roboto;
  font-size: 24px;
  text-align: left;
  font-weight: bold;
  margin-top: 31px;
  margin-bottom: 31px;
  color: ${colors.navy};
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 18px;
    text-align: center;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justifyContent || 'space-between'};
  ${props => props.marginLeft && 'margin-left: 41px;'}
  ${props => props.alignItems && `align-items: ${props.alignItems};`}
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: ${props => props.marginBottom || '30'}px;
    margin-left: 0px;
    ${props => !props.row && 'flex-direction: column;'}
    ${props => props.marginsMobile && 'margin: 0px 18px;'}
  }
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  padding-left: 46px;
  margin-top: 28px;
  ${props => props.marginLeft && 'margin-left: -46px;'}
  ${props => props.marginRight && 'margin-right: 46px;'}
  @media (max-width: ${mobileThresholdPixels}) {
    width: auto;
    margin: 28px 0 0 0;
    padding: 0px 40px;
  }
`;

const OrderSummaryContainer = styled.div`
  ${props => !props.flex && `background-color: ${colors.lightGrey};`}
  ${props => props.flex && 'display: flex; flex-direction: column; align-items: center; justify-content: center;'}
  padding-left: 46px;
  padding-right: 46px;
  ${props => !props.noPaddingBottom && 'padding-bottom: 31px;'}
  ${props => !props.noMarginBottom && 'margin-bottom: 24px;'}
  width: 100%;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-left: -25px;
    padding: 0px 25px 31px 25px;
    margin-bottom: 40px;
  }
`;

const TextPaymentContainerFooter = styled.p`
  font-family: Roboto;
  font-size: 18px;
  text-align: left;
  margin-bottom: 15px;
  margin-top: 30px;
  color: ${colors.navy};
  opacity: 0.3;
  margin-right: 15px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const TextGuarantees = styled.p`
  font-family: Roboto;
  font-size: 15px;
  color: ${colors.darkGrey};
  ${props => props.italics && 'font-style: italic; font-weight: 300;'}
`;

const StyledImg = styled.img`
  width: 47px;
  height: 47px;
  margin-right: 15px;
`;

const ImgAllowedCBs = styled.img`
  @media (max-width: ${mobileThresholdPixels}) {
    width: 136px;
  }
`;

class PaymentPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
    };
    this.inputChange = this.inputChange.bind(this);
    this.submit = this.submit.bind(this);
    this.fetchOrder = this.fetchOrder.bind(this);
    this.setStatesFromOrder = this.setStatesFromOrder.bind(this);
  }

  componentDidMount() {
    const { order, orderId } = this.props;
    if (!order) {
      this.fetchOrder(orderId);
    } else {
      this.setStatesFromOrder(order);
    }
  }

  setStatesFromOrder(orderToPay) {
    this.setState({
      isLoaded: true,
      orderToPay,
    });
  }

  fetchOrder(orderId) {
    callApi(`public/order/${orderId}`).then(({ order }) => {
      this.setStatesFromOrder(order);
    });
  }

  validateCardNumber(cardNumber) {
    const strippedCardNumber = cardNumber.replace(/ /g, '');
    if (!isCardTypeValid(strippedCardNumber)) {
      this.setState({
        cardError: 'les cartes American Express (Amex) ne sont pas supportées',
      });
    } else if (
      strippedCardNumber &&
      strippedCardNumber.length > 15 &&
      !isCardNumberValid(strippedCardNumber)
    ) {
      this.setState({
        cardError: 'Oups une erreur s\'est glissé. Êtes-vous sûr du numéro ?',
      });
    } else {
      this.setState({ cardError: null });
    }
  }

  inputChange(field, value) {
    if (field === 'cardNumber') this.validateCardNumber(value);
    this.setState({ [field]: value });
  }

  isValid() {
    const { expiryMonth, expiryYear, cvv, cardNumber } = this.state;
    return (
      expiryMonth &&
      expiryYear &&
      cvv &&
      cardNumber &&
      isCardTypeValid(cardNumber) &&
      isCardNumberValid(cardNumber)
    );
  }

  submit() {
    if (!this.isValid()) {
      return this.setState({ formError: true });
    }
    const { expiryMonth, expiryYear, cvv, cardNumber, cardId } = this.state;
    const { orderId, customerId } = this.props;
    const expiryDate = `${expiryMonth}${expiryYear}`;
    const card = { expiryDate, cvv, cardNumber, cardId };
    return this.props.submit(card, orderId, customerId);
  }

  renderError() {
    const { error, errorType } = this.props;
    const message =
      (errorType && errorMessages[errorType]) || 'Une erreur a eu lieu';
    return (
      <div>
        {error && <ErrorText>{message}</ErrorText>}
        {this.state.formError && (
          <ErrorText>Merci de remplir tous les champs</ErrorText>
        )}
      </div>
    );
  }

  render() {
    const {
      isLoaded,
      cardName, cardNumber, cvv,
      orderToPay,
    } = this.state;
    const { orderId, customerId } = this.props;
    if (!orderId || !customerId || !isLoaded) return <h1>Chargement</h1>;
    return (
      <Container>
        <MenuBar />
        <Content>
          <TextTitle>
            Enregistrez votre carte pour confirmer votre rendez-vous.
          </TextTitle>
          <TextSubTitle>
            Vous ne serez débité qu’une seule fois, à la fin de votre premier
            rendez-vous, après validation du devis avec votre Tilliste.
          </TextSubTitle>
          <StyledContainer marginBottom="0">
            <SubContainer marginLeft>
              <StyledContainer alignItems="center" row marginBottom="0">
                <TextTitleContainer>
                  Règlement 100% sécurisé via MangoPay
                </TextTitleContainer>
                <img src={mangoPayLogo} alt="Logo MangoPay" />
              </StyledContainer>
              <CreditCard2
                onChange={this.inputChange}
                formError={this.state.formError}
                cardName={cardName}
                cardNumber={cardNumber}
                cvv={cvv}
                error={this.state.cardError}
              />
              <StyledContainer alignItems="center" justifyContent="right" row marginBottom="47">
                <TextPaymentContainerFooter>Nous acceptons : </TextPaymentContainerFooter>
                <ImgAllowedCBs src={acceptedCBs} alt="Moyens de paiement acceptés" />
              </StyledContainer>
            </SubContainer>
            <SubContainer marginRight>
              <OrderSummaryContainer>
                <TextTitleContainer>
                  Récapitulatif de commande
                </TextTitleContainer>
                <OrderSummary orderToPay={orderToPay} />
              </OrderSummaryContainer>
              <OrderSummaryContainer flex noMarginBottom noPaddingBottom>
                {this.props.isLoading ?
                  <Button navy>{'chargement…'}</Button> :
                  <Button navy onClick={this.submit}>Valider</Button>
                }
                {this.renderError()}
              </OrderSummaryContainer>
            </SubContainer>
          </StyledContainer>
        </Content>
        <StyledContainer marginLeft justifyContent="left" row marginsMobile alignItems="center">
          <StyledImg src={axaLogo} alt="Logo Axa" />
          <TextGuarantees>Vos vêtements sont garantis par notre partenaire AXA.</TextGuarantees>
        </StyledContainer>
        <StyledContainer marginLeft justifyContent="left" marginsMobile>
          <TextGuarantees italics>
            Conformément aux réglementations de la CNIL destinées à renforcer
            la protection des données personnelles des consommateurs sur les sites marchands,
            vos données sont cryptées et sécurisées
          </TextGuarantees>
        </StyledContainer>
        <Footer />
      </Container>
    );
  }
}

PaymentPage.propTypes = {
  order: PropTypes.shape({}),
  submit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.bool,
  errorType: PropTypes.string,
  customerId: PropTypes.string,
  orderId: PropTypes.string,
};

PaymentPage.defaultProps = {
  order: null,
  isLoading: false,
  error: false,
  errorType: null,
  customerId: null,
  orderId: null,
};

export default PaymentPage;
